<template>
  <div>
    <el-table border
              stripe
              highlight-current-row
              v-if="info"
              :max-height="$store.state.tableHeight + 100"
              :data="info"
              style="width: 100%">
      <el-table-column prop="id"
                       align="center"
                       width="150"
                       label="考点ID" />
      <el-table-column prop="text"
                       align="center"
                       label="考点名称" />
      <el-table-column prop="doQuestionCount"
                       align="center"
                       label="学生做题次数" />
      <el-table-column prop="rate"
                       align="center"
                       label="学生得分率" />
    </el-table>
  </div>
</template>

<script>
export default {
  data () {
    return {
      info: [],
    }
  },
  created () {
    this.initData()
  },
  methods: {
    initData () {
      let form = {
        student_id: this.$route.query.student_id,
        subject_id: this.$route.query.subject_id,
        check_point_id: this.$route.query.check_point_id,
        start_time: this.$route.query.start_time,
        end_time: this.$route.query.end_time
      }

      this.$http({
        url: '/api/v1/weak/student_detail_second',
        method: 'post',
        data: form
      }).then(res => {
        this.info = res.data
      })
    },
  }
}
</script>

<style lang="scss" scoped>
</style>
